<template>
    <div full-height style="margin:0px;padding:0px;overflow:hidden">
        <!--        TODO: Modify to show real content. If possible show Dashboard for users.-->
        <iframe
                frameborder="0"
                height="90%"
                src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFVf6xkjo9eejgBvJYlV_obhkx-1zkNxqcTBQAZN8ZwLWg-XskK7lCo8Chk1FH20ZfW4GQp7A9FMKv/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"
                style="position: absolute;overflow:hidden;height:100%;width:100%"
                width="100%"
        ></iframe>
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from "@/components/TestCard.vue";

    export default {
        components: {
            // HelloWorld
        }
    };
</script>
